
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Extro',

  mounted () {
    // console.log('XXXXXXX')
    // console.log(this.$refs.video)
    setTimeout(() => {
      (this.$refs.video as HTMLVideoElement).play()
    }, 400)
    console.log('XXXXXXXXX')
    // console.log(new Date())
    // console.log(this.video)
    // this.video.play()
  },

  methods: {
    onEnd () {
      this.$router.push('intro')
    },

    restart () {
      this.$router.push('tictactoe')
    },

    info () {
      this.$router.push('sources')
    }
  }
})
