<template>
<div class="video-wrapper">
  <div class="restart" v-on:click="restart"></div>
  <div class="info" v-on:click="info"></div>
  <video class="video" id="video" ref="video" v-on:ended="onEnd()" crossOrigin="anonymous" muted playsinline autoplay>
    <source src="video/Extro-200721.mp4" type="video/mp4" />
  </video>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Extro',

  mounted () {
    // console.log('XXXXXXX')
    // console.log(this.$refs.video)
    setTimeout(() => {
      (this.$refs.video as HTMLVideoElement).play()
    }, 400)
    console.log('XXXXXXXXX')
    // console.log(new Date())
    // console.log(this.video)
    // this.video.play()
  },

  methods: {
    onEnd () {
      this.$router.push('intro')
    },

    restart () {
      this.$router.push('tictactoe')
    },

    info () {
      this.$router.push('sources')
    }
  }
})
</script>
